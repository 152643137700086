import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 612.000000 428.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1008.000000) scale(0.100000,-0.100000)">
          <path d="M4953 9135 c-37 -16 -63 -60 -63 -109 0 -26 9 -44 34 -70 l33 -36 90
0 c49 0 92 -3 96 -6 3 -3 1 -17 -5 -30 -8 -18 -7 -37 6 -82 9 -32 16 -63 16
-69 0 -6 -36 -13 -87 -17 -724 -57 -1345 -539 -1577 -1226 -214 -636 -56
-1336 413 -1823 763 -793 2061 -718 2727 158 415 545 486 1277 184 1886 -216
435 -589 762 -1040 913 -124 41 -320 82 -442 91 -99 8 -97 5 -72 92 12 41 13
61 6 78 -6 13 -8 26 -5 29 4 3 42 6 85 6 91 0 130 15 153 60 19 38 19 75 -1
110 -34 57 -51 60 -296 60 -167 -1 -231 -4 -255 -15z m437 -526 c264 -32 499
-115 723 -255 229 -142 451 -379 584 -622 90 -164 157 -360 189 -552 20 -116
22 -381 5 -499 -103 -704 -627 -1269 -1323 -1425 -63 -14 -122 -26 -130 -26
-29 0 -133 284 -172 467 -33 156 -46 284 -46 443 l0 130 -59 0 -59 0 -7 -73
c-4 -40 -2 -140 5 -222 18 -243 60 -424 147 -639 24 -59 41 -111 38 -114 -9
-9 -218 -3 -310 9 -157 20 -396 94 -539 166 -475 241 -818 707 -911 1238 -113
648 185 1334 738 1698 239 158 485 245 792 281 69 8 256 5 335 -5z"/>
<path d="M4666 7839 c-25 -36 -20 -54 29 -109 66 -74 83 -116 38 -92 -25 13
-156 42 -192 42 -58 0 -38 -17 77 -65 180 -75 273 -136 447 -297 17 -15 32
-28 35 -28 3 0 39 33 81 73 99 95 199 153 424 247 101 42 191 83 200 90 22 19
18 38 -15 72 l-30 30 -118 -7 c-178 -10 -240 -22 -317 -60 -38 -19 -92 -55
-118 -80 l-48 -47 -57 54 c-142 134 -238 188 -352 196 -62 4 -68 3 -84 -19z"/>
<path d="M5730 7521 c-238 -92 -281 -112 -367 -168 -80 -52 -173 -146 -173
-174 0 -8 16 -41 36 -73 96 -160 127 -253 169 -509 27 -160 29 -167 49 -161
54 16 138 70 190 122 109 108 149 230 180 548 20 201 35 249 112 349 38 50 45
84 24 110 -21 25 -68 15 -220 -44z"/>
<path d="M4559 7482 c44 -62 45 -75 46 -382 1 -320 5 -352 61 -462 54 -106
176 -195 314 -229 59 -15 283 -19 296 -6 30 29 6 247 -42 392 -71 217 -250
462 -430 591 -63 45 -188 107 -237 118 l-29 7 21 -29z"/>
<path d="M4459 6353 c-6 -15 -13 -68 -16 -118 -4 -77 -2 -99 18 -150 46 -122
168 -205 302 -205 85 0 135 16 174 55 30 30 33 39 33 89 0 135 -63 196 -262
253 -103 29 -156 51 -205 83 l-33 22 -11 -29z"/>
<path d="M5894 5935 c-34 -15 -172 -35 -239 -35 -145 0 -254 -97 -255 -226 0
-60 24 -88 110 -128 83 -39 185 -37 268 4 108 55 182 166 190 287 7 111 -6
128 -74 98z"/>
<path d="M2640 4204 c-206 -37 -331 -143 -396 -334 -15 -45 -19 -84 -19 -210
1 -145 3 -160 28 -225 34 -85 60 -127 118 -183 136 -134 389 -169 578 -82 112
53 183 130 233 255 32 79 33 83 32 240 0 146 -2 166 -25 225 -60 157 -156 249
-308 296 -48 15 -198 26 -241 18z m196 -141 c67 -23 145 -103 174 -179 72
-193 39 -435 -75 -550 -60 -60 -120 -84 -215 -84 -95 0 -155 24 -215 84 -114
115 -147 357 -75 550 28 73 107 156 170 179 60 21 174 21 236 0z"/>
<path d="M3410 3665 l0 -535 360 0 360 0 0 70 0 70 -280 0 -280 0 0 465 0 465
-80 0 -80 0 0 -535z"/>
<path d="M4465 4008 c-37 -106 -94 -268 -126 -360 -124 -353 -179 -510 -179
-514 0 -3 39 -3 87 -2 l86 3 51 160 c28 88 55 168 58 178 7 15 26 17 193 17
140 0 187 -3 190 -12 2 -7 28 -88 57 -180 l53 -168 87 0 c49 0 88 2 88 4 0 3
-17 54 -212 621 -60 176 -119 348 -131 383 l-21 62 -106 0 -107 0 -68 -192z
m215 -80 c23 -73 53 -167 66 -208 13 -41 24 -80 24 -87 0 -10 -33 -13 -141
-13 -109 0 -140 3 -136 13 2 6 35 105 73 220 38 114 70 207 71 207 1 0 21 -60
43 -132z"/>
<path d="M5240 3665 l0 -535 80 0 80 0 0 535 0 535 -80 0 -80 0 0 -535z"/>
<path d="M5525 4135 l0 -65 183 0 182 0 0 -470 0 -470 80 0 80 0 2 468 3 467
183 3 182 2 0 65 0 65 -447 0 -448 0 0 -65z"/>
<path d="M6670 4108 c-17 -51 -101 -290 -186 -530 -85 -241 -154 -440 -154
-443 0 -3 39 -4 87 -3 l86 3 51 160 c28 88 55 168 58 178 7 15 26 17 193 17
140 0 187 -3 190 -12 2 -7 28 -88 57 -180 l53 -168 87 0 c49 0 88 2 88 5 0 2
-54 161 -120 352 -65 191 -147 430 -181 531 l-61 182 -108 0 -107 0 -33 -92z
m213 -280 l65 -208 -145 0 c-112 0 -144 3 -140 13 2 6 35 105 73 219 38 114
71 202 75 195 4 -6 36 -105 72 -219z"/>
<path d="M7410 3665 l0 -535 80 0 80 0 -2 435 c-2 239 -1 435 2 435 3 0 108
-196 234 -435 l229 -435 118 0 119 0 0 535 0 535 -80 0 -80 0 -2 -445 -3 -445
-37 73 c-59 113 -173 331 -307 585 l-123 232 -114 0 -114 0 0 -535z"/>
<path d="M3310 2355 c0 -104 2 -125 15 -125 12 0 15 13 15 55 l0 55 60 0 c47
0 60 3 60 15 0 12 -13 15 -60 15 l-60 0 0 40 0 40 65 0 c51 0 65 3 65 15 0 12
-16 15 -80 15 l-80 0 0 -125z"/>
<path d="M3889 2453 c-6 -16 -26 -72 -44 -125 -32 -92 -33 -98 -15 -98 14 0
23 11 31 40 12 39 13 40 59 40 46 0 47 -1 60 -40 9 -27 19 -40 32 -40 10 0 18
3 18 8 0 4 -16 52 -36 107 -20 55 -38 108 -41 118 -9 28 -52 22 -64 -10z m61
-100 c0 -8 -13 -13 -31 -13 -34 0 -35 8 -8 77 l11 28 14 -40 c7 -22 14 -46 14
-52z"/>
<path d="M4413 2460 c-59 -36 -41 -87 42 -117 38 -14 51 -24 53 -42 6 -43 -52
-57 -88 -21 -18 18 -50 28 -50 14 0 -20 25 -44 55 -54 63 -21 125 9 125 60 0
33 -23 55 -75 73 -43 15 -61 36 -52 61 9 24 62 21 81 -5 8 -12 22 -19 33 -17
16 3 14 8 -14 36 -36 37 -65 40 -110 12z"/>
<path d="M4900 2465 c0 -11 12 -15 45 -15 l45 0 0 -110 c0 -91 3 -110 15 -110
12 0 15 17 16 85 2 140 -1 130 47 133 28 2 42 8 42 18 0 11 -20 14 -105 14
-87 0 -105 -3 -105 -15z"/>
<path d="M5925 2468 c-78 -216 -84 -238 -65 -238 14 0 23 11 31 40 12 39 13
40 60 40 48 0 48 -1 60 -40 9 -28 18 -40 31 -40 10 0 18 2 18 5 0 3 -19 59
-41 125 -38 109 -44 120 -65 120 -14 0 -27 -6 -29 -12z m54 -110 c1 -14 -7
-18 -30 -18 -28 0 -31 2 -25 25 3 13 11 37 17 52 l11 28 13 -35 c8 -19 14 -43
14 -52z"/>
<path d="M6430 2355 c0 -104 2 -125 15 -125 12 0 15 17 15 95 0 52 3 95 8 95
4 0 29 -43 56 -95 44 -87 51 -95 77 -95 l29 0 0 125 c0 118 -1 125 -20 125
-19 0 -20 -7 -20 -102 l0 -103 -56 103 c-47 86 -59 102 -80 102 l-24 0 0 -125z"/>
<path d="M7020 2355 l0 -127 71 4 c65 3 74 6 100 36 24 27 29 41 29 87 0 46
-5 60 -29 87 -26 30 -35 33 -100 36 l-71 4 0 -127z m135 70 c19 -18 25 -35 25
-70 0 -61 -32 -95 -90 -95 l-40 0 0 95 0 95 40 0 c30 0 47 -7 65 -25z"/>
<path d="M3140 1925 c0 -104 2 -125 15 -125 11 0 15 12 15 50 l0 50 40 0 c43
0 51 -9 64 -72 4 -18 13 -28 25 -28 16 0 17 4 6 43 -6 23 -15 50 -20 60 -5 12
-2 22 9 30 13 9 17 24 14 52 -4 49 -30 65 -110 65 l-58 0 0 -125z m124 79 c19
-18 19 -16 6 -48 -8 -23 -15 -26 -55 -26 l-45 0 0 45 0 45 39 0 c23 0 46 -7
55 -16z"/>
<path d="M3700 1925 l0 -125 80 0 c64 0 80 3 80 15 0 12 -14 15 -65 15 l-65 0
0 40 0 40 60 0 c47 0 60 3 60 15 0 12 -13 15 -60 15 l-60 0 0 40 0 40 65 0
c51 0 65 3 65 15 0 12 -16 15 -80 15 l-80 0 0 -125z"/>
<path d="M4240 1925 l0 -125 80 0 c63 0 80 3 80 15 0 10 -16 15 -62 17 l-63 3
-3 108 c-2 86 -6 107 -17 107 -12 0 -15 -22 -15 -125z"/>
<path d="M4770 1925 c0 -104 2 -125 15 -125 13 0 15 21 15 125 0 104 -2 125
-15 125 -13 0 -15 -21 -15 -125z"/>
<path d="M5249 2023 c-6 -16 -26 -72 -44 -125 -32 -92 -33 -98 -15 -98 14 0
23 11 31 40 12 39 13 40 60 40 48 0 48 -1 60 -40 9 -29 18 -40 32 -40 11 0 17
5 15 13 -3 6 -22 63 -42 125 -34 102 -40 112 -62 112 -18 0 -27 -8 -35 -27z
m60 -95 c1 -14 -7 -18 -30 -18 -28 0 -31 2 -25 25 3 13 11 37 17 52 l11 28 13
-35 c8 -19 14 -43 14 -52z"/>
<path d="M5750 1925 l0 -125 49 0 c64 0 106 16 119 45 13 27 2 70 -19 77 -9 3
-7 12 6 31 14 23 16 32 7 53 -15 30 -49 44 -113 44 l-49 0 0 -125z m124 73
c18 -29 -4 -53 -53 -56 l-41 -3 0 41 0 41 41 -3 c27 -2 46 -9 53 -20z m6 -107
c13 -25 13 -27 -6 -45 -9 -9 -32 -16 -55 -16 -39 0 -39 0 -39 40 l0 40 45 0
c34 0 47 -5 55 -19z"/>
<path d="M6310 1925 l0 -125 85 0 c63 0 84 3 83 13 -2 7 -27 14 -68 17 l-65 5
-3 108 c-2 86 -6 107 -17 107 -12 0 -15 -22 -15 -125z"/>
<path d="M6840 1925 l0 -125 80 0 c64 0 80 3 80 15 0 12 -14 15 -65 15 l-65 0
0 40 0 40 60 0 c46 0 60 3 60 15 0 10 -15 15 -57 17 l-58 3 -3 38 -3 37 65 0
c52 0 66 3 66 15 0 12 -16 15 -80 15 l-80 0 0 -125z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
